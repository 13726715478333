//@ts-check
import { createContext } from "react";

export const UserContext = createContext(null);

export const userContextData = {
  loggedIn: false,
  userId: "",
  username: "",
};
