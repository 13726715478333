//@ts-check
import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Additional Libraries
import WebFont from "webfontloader";
import awsconfig from "../aws-exports";
import Amplify, { Auth } from "aws-amplify";
import { ThemeProvider } from "@material-ui/styles";

// Import Immediate Components
import { UserContext } from "./context/UserContext";
import Loading from "./common/Loading";
import Theme from "./common/Theme";
import "../styles/GlobalStyles.scss";

// Lazy Loaded Components
const UserScreen = lazy(() => import("./main/UserScreen"));
const MainScreen = lazy(() => import("./main/MainScreen"));

// Configure AWS
Amplify.configure(awsconfig);

const App = () => {
  // Set user data for context
  const [userData, setUserAccount] = useState({});

  // Check user login state against Congnito
  const CheckUserState = async () => {
    console.log("App.jsx - checking login state...");
    try {
      const authResponse = await Auth.currentAuthenticatedUser();

      // Set for user context
      setUserAccount({
        loggedIn: true,
        userId: authResponse.attributes.sub,
        username: authResponse.username,
      });
      // console.log(' - logged in');
      return true;
    } catch (error) {
      // User not logged in. Ensure that state reflects that
      setUserAccount({ loggedIn: false, userId: "", username: "" });
      // console.log(' - not logged in');
      return false;
    }
  };

  // Upon initial load
  useEffect(() => {
    WebFont.load({
      google: { families: ["Lato", "Roboto", "Nanum Gothic Coding"] },
    });
    CheckUserState();
  }, []);

  return (
    <React.Fragment>
      {/* Once Hooks confirm user logged in state, re-render with Routing */}
      {"loggedIn" in userData && (
        <UserContext.Provider value={{ userData, CheckUserState }}>
          <ThemeProvider theme={Theme}>
            <Router>
              <Switch>
                <Route strict path="/users">
                  <Suspense fallback={<Loading />}>
                    <UserScreen />
                  </Suspense>
                </Route>
                <Route path="/:category/:id?/:subcategory?/:subid?">
                  <Suspense fallback={<Loading />}>
                    <MainScreen />
                  </Suspense>
                </Route>
                <Route>
                  {userData["loggedIn"] ? (
                    <Suspense fallback={<Loading />}>
                      <MainScreen />
                    </Suspense>
                  ) : (
                    <Suspense fallback={<Loading />}>
                      <UserScreen />
                    </Suspense>
                  )}
                </Route>
              </Switch>
            </Router>
          </ThemeProvider>
        </UserContext.Provider>
      )}
    </React.Fragment>
  );
};

export default App;
