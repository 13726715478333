/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:aa95d560-e6cb-45ba-904e-e628f36df507",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_UAO5DeIvH",
    "aws_user_pools_web_client_id": "6lm282qtr6lb2ss40lt3k33tkj",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://bvx53fc57jckzngeoicj65lpiy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
