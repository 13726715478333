import { createMuiTheme } from "@material-ui/core/styles";

const Theme = createMuiTheme({
  typography: {
    fontFamily: `"Roboto", "Lato", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    lineHeight: 1.25,
    body1: {
      lineHeight: 1.5,
    },
    h6: {
      lineHeight: 1.25,
    },
  },
});

export default Theme;
